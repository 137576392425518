import React from 'react'
import { InventionIcon, SimplicityIcon, WarmthIcon } from '../../lib/components/basics/icons'
import Icon from '../../lib/components/basics/icon'
import aboutUsHero from '../../../resources/images/hanzo/about-us/hero-about-us.webp'
import aboutUsTeam from '../../../resources/images/hanzo/about-us/about-us-team.webp'

// HERO
export const heroProps = {
    title: 'Who we are',
    description: (
        <p>
            Building software products and bringing them to the world is a rollercoaster. Boy do we
            know it.
        </p>
    ),
    hasOverlay: true,
    background: {
        image: {
            src: aboutUsHero,
            alt: 'hero-bg',
        },
    },
}

// TEXT BLOCK
export const textBlockProps = {
    title: (
        <div>
            Technology is complex.
            <br /> <b>We make it clear.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                When telling the Lenses story, we could write about the collective exhale after
                fixing a tricky development issue. We could reflect on the sprint that turned into
                marathons. We could look back on our early open-source contributions to the Kafka
                community, in 2016 when the technology’s success was still a glimmer on the horizon.
            </p>
        </div>,
        <div key='2'>
            <p>
                We could list the highs, the lows, the stops, starts; the features shipped. The rush
                of hitting revenue milestones. The long days leading up to a release. But for us,
                the people part of developing software has always been what it’s really about.
            </p>
        </div>,
        <div key='3'>
            <p>
                It is helping our customers to find data they need; helping them to make the most of
                this new world of streaming. It is the moment when people can freely and
                independently do their work, without an endless dependence on a central team. It is
                making sure a complex technology can help people to fulfill their potential, to
                build the real-time services we all rely on.
            </p>
        </div>,
    ],
}

// FULL SIZE IMAGE
export const fullSizeImageHero = {
    background: {
        image: {
            src: aboutUsTeam,
            alt: 'full size image',
        },
    },
}

// TEXT BLOCK 2
export const textBlock2Props = {
    title: (
        <div>
            For us, the comfort zone
            <br /> <b>is uncomfortable.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                Most people see a company as something you build and then invite a team to move
                into. At Lenses, we&apos;ve grown differently. We explore and learn alongside our
                customers and partners every day. We continue to listen, and contribute projects to
                the community. We try new things, see what works, and change direction when needed.
            </p>
        </div>,
        <div key='2'>
            <p>
                This approach isn&apos;t just because we&apos;re doing something new - it&apos;s at
                the heart of who we are. Sure, it feels safe to do what we know will work. But
                testing new ideas? That&apos;s scary. And that&apos;s exactly what we love - solving
                tough problems no one has cracked before and turning bold ideas into reality. It’s
                what gets us excited.
            </p>
        </div>,
    ],
}

// TEXT BLOCK 3
export const textBlock3Props = {
    title: (
        <div>
            Same Lenses
            <br /> <b>re-architected for the future.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                So when the team brought a big challenge to the table at an offsite – unifying the
                developer experience across different streaming technologies – we couldn’t pass it
                up. We came up with a big idea to re-architect our product.
            </p>
        </div>,
        <div key='2'>
            <p>
                It’s called Lenses 6 Panoptes. It keeps all the things users love about Lenses, but
                does them bigger, better, and more beautifully.
            </p>
        </div>,
        <div key='3'>
            <p>
                Today, we love watching the ripple of excitement when we explain to customers that a
                hybrid data infrastructure doesn&apos;t have to mean data silos. When we show people
                that they can tap into every single team’s streams, to build something new that
                responds to the world as it happens, and is greater than the sum of its parts.
            </p>
        </div>,
        <div key='4'>
            <p>
                Our product has evolved, and so have we as a company. We’ve kept what makes us “us”,
                but our potential has grown. We hope that Lenses helps you realize your full
                potential too.
            </p>
        </div>,
    ],
}

// PRE-FOOTER
export const preFooterTitle = 'Our beliefs'

export const cardsDataPrefooter = [
    {
        title: 'Warmth',
        type: 'secondary' as const,
        description: ['Caring outperforms not caring, every time.'],
        icon: <Icon icon={<WarmthIcon />} size='xl' />,
    },
    {
        title: 'Simplicity',
        type: 'secondary' as const,
        description: ['Technology is complex. We make it clear.'],
        icon: <Icon icon={<SimplicityIcon />} size='xl' />,
    },
    {
        title: 'Invention',
        type: 'secondary' as const,
        description: ['For us, the comfort zone is uncomfortable.'],
        icon: <Icon icon={<InventionIcon />} size='xl' />,
    },
]
